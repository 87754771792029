$breakpoints: (
    mobile: $breakpoints-s * 1px,
    tablet: $breakpoints-m * 1px,
    small_desktop: $breakpoints-l * 1px,
    large_desktop: $breakpoints-xl * 1px
);

$there-is-no-higher-breakpoint: 9999em;

$allowed-breakpoint-targets: inspect(map-keys($breakpoints));

@function get-breakpoint($target) {
    @if (type-of($target) == number and $target >= 0) {
        @return $target * 1px;
    }

    $breakpoint: map-get($breakpoints, $target);

    @if $breakpoint == null {
        @warn('You tried to respond_to \'#{$target}\'. Please use one of these available breakpoints - #{$allowed-breakpoint-targets}');
    }

    @return $breakpoint;
}

@mixin when-smaller-than($target) {
    $max-target: get-breakpoint($target) - 1;

    @media screen and (max-width:  $max-target) {
        @content;
    }
}

@mixin when-wider-than($target) {
    @media screen and (min-width: get-breakpoint($target)) {
        @content;
    }
}

@mixin when-between($target1, $target2) {
    $max-target2: get-breakpoint($target2) - 1;

    @media screen and (min-width: get-breakpoint($target1)) and (max-width: $max-target2) {
        @content;
    }
}
