@use 'sass:math';

$loader-width: 100px;
$loader-dot-size: 14px;
$loader-half-width: math.div($loader-width, 2);

$loader-circle-size: 22px;
$loader-stroke-width: 2px;

@keyframes ellipsis-one {
    0% {
        left: -$loader-dot-size;
        transform: scale(0);
    }

    25% {
        left: $loader-dot-size;
        transform: scale(1);
    }

    50% {
        left: $loader-half-width;
    }

    75% {
        left: $loader-width - $loader-dot-size;
        transform: scale(1);
    }

    100% {
        left: $loader-width;
        transform: scale(0);
    }
}

.btn-loader {
    position: absolute;
    width: $loader-width;
    height: $loader-dot-size;

    & > div {
        position: absolute;
        transform: translate(-50%, -50%);
        width: $loader-dot-size;
        height: $loader-dot-size;
    }
}

.btn-loader div > div {
    width: $loader-dot-size;
    height: $loader-dot-size;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: $loader-dot-size;
    animation: ellipsis-one 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}

.standard-promise-btn {
    overflow: hidden;

    span {
        transition: 0.3s ease-in-out opacity, 0.3s ease-in-out visibility, 0.3s ease-in-out transform;
    }

    span:not(.btn-loader) {
        opacity: 1;
        transform: scale(1);
    }

    .btn-loader {
        opacity: 0;
        visibility: hidden;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%) scale(1.5);
    }

    &.is-loading {
        span:not(.btn-loader) {
            opacity: 0;
            transform: scale(0.5);
        }

        .btn-loader {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.promise-btn {
    @extend .standard-promise-btn;
    min-width: $loader-width + $loader-dot-size;

    .btn-loader div:nth-child(1) div {
        animation-delay: -1.6s;
        background: $white;
    }

    .btn-loader div:nth-child(2) div {
        animation-delay: -1.2s;
        background: $white;
    }

    .btn-loader div:nth-child(3) div {
        animation-delay: -0.8s;
        background: $white;
    }

    .btn-loader div:nth-child(4) div {
        animation-delay: -0.4s;
        background: $white;
    }

    .btn-loader div:nth-child(5) div {
        animation-delay: 0s;
        background: $white;
    }
}

.promise-btn-secondary {
    @extend .standard-promise-btn;
    min-width: $loader-width + $loader-dot-size;

    .btn-loader div:nth-child(1) div {
        animation-delay: -1.6s;
        background: $sky-blue;
    }

    .btn-loader div:nth-child(2) div {
        animation-delay: -1.2s;
        background: $sky-blue;
    }

    .btn-loader div:nth-child(3) div {
        animation-delay: -0.8s;
        background: $sky-blue;
    }

    .btn-loader div:nth-child(4) div {
        animation-delay: -0.4s;
        background: $sky-blue;
    }

    .btn-loader div:nth-child(5) div {
        animation-delay: 0s;
        background: $sky-blue;
    }
}

.promise-btn-circle {
    @extend .standard-promise-btn;

    .btn-loader > div {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        width: $loader-circle-size;
        height: $loader-circle-size;
        transform: translate(-50%, -50%);

        &:nth-child(1) {
            display: block;

            div {
                display: block;
                position: relative;
                top: 0;
                left: 0;
                border: $loader-stroke-width solid $white;
                border-top-color: $transparent;
                border-left-color: $transparent;
                width: $loader-circle-size;
                height: $loader-circle-size;
                border-radius: 50%;
                animation: spin 0.5s infinite linear;
            }
        }
    }

    .btn-loader {
        position: absolute;
        width: $loader-circle-size;
        height: $loader-circle-size;
    }
}

.promise-btn-circle-secondary {
    @extend .promise-btn-circle;

    .btn-loader > div:nth-child(1) {
        div {
            border-color: $core-blue;
            border-top-color: $transparent;
            border-left-color: $transparent;
        }
    }
}
