$xfinity-standard-fallback:   helvetica, arial, sans-serif;
$xfinity-standard:            'Lato';
$xfinity-standard-condensed:  'Montserrat';

@mixin xfinity-standard-thin {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
}

@mixin xfinity-standard-thin-italic {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }
}

@mixin xfinity-standard-light {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
}

@mixin xfinity-standard-light-italic {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }
}

@mixin xfinity-standard-regular {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
}

@mixin xfinity-standard-regular-italic {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }
}

@mixin xfinity-standard-bold {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
}

@mixin xfinity-standard-bold-italic {
    @font-face {
        font-family: 'Lato';
        src: url($static-font-url + 'Lato-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    }
}

@mixin xfinity-secondary-bold {
    @font-face {
        font-family: 'Montserrat';
        src: url($static-font-url + 'Montserrat-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
}

@mixin xfinity-secondary-semibold {
    @font-face {
        font-family: 'Montserrat';
        src: url($static-font-url + 'Montserrat-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }
}

@mixin xfinity-secondary-regular {
    @font-face {
        font-family: 'Montserrat';
        src: url($static-font-url + 'Montserrat-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
}

@mixin xfinity-standard-all {
    @include xfinity-standard-thin;
    @include xfinity-standard-thin-italic;
    @include xfinity-standard-light;
    @include xfinity-standard-light-italic;
    @include xfinity-standard-regular;
    @include xfinity-standard-regular-italic;
    @include xfinity-standard-bold;
    @include xfinity-standard-bold-italic;
    @include xfinity-secondary-bold;
    @include xfinity-secondary-semibold;
    @include xfinity-secondary-regular;
}

@include xfinity-standard-all;
