xm-landing-faqs {
    .mat-expansion-panel-body {
        padding: 0;
    }

    .bbl-faqs-accordion {
        .mat-expansion-panel-body {
            padding-left: step(3);
            padding-right: step(3);
            padding-top: 0;
            padding-bottom: vr(2);
        }
    }

    .mat-expansion-indicator {
        margin-right: 2px;

        &::after {
            color: $core-blue;
        }
    }
}
