// stylelint-disable
// Primary Color Palette
$white: #FFFFFF;
$cool-grey1: #FBFCFC;
$cool-grey2: #F2F4F5;
$cool-grey3: #EEF1F3;
$cool-grey4: #EBEEF0;
$cool-grey5: #E6EAED;
$cool-grey6: #DDE2E6;
$cool-grey7: #C7CDD2;
$cool-grey8: #B1B9BF;
$cool-grey9: #9BA4AA;
$cool-grey10: #868F96;
$cool-grey11: #646A70;
$cool-grey12: #44484C;
$cool-grey13: #2A2C2D;
$cool-grey14: #191919;
$cool-grey15: #CEF0FF;
$cool-grey16: #CCCCCC;
$cool-grey17: #414141;
$cool-grey18: #DADADA;
$cool-grey19: #979797;
$cool-grey20:#757575;
$cool-grey21: #303030;
$cool-grey22: #F7F7F7;
$cool-grey23: #393939;
$cool-grey24: #DDDDDD;
$cool-grey25: #E5E5E5;
$cool-grey26: #D2D2D2;
$cool-grey27: #E7E7E7;
$cool-grey28: #D8D8D8;
$cool-grey29: #B2B2B2;
$cool-grey30: #5E5E5E;
$black: #000000;



$dark-blue: #015383;
$link-blue: #1471DA;
$deep-blue: #0B7DBB;
$sharp-blue: #256CEE;
$sky-blue: #0272B6;
$ocean-blue: #00619A;
$vivid-blue: #2B9CD8;
$vivid-blue-hover: #3FB6F5;
$navy-blue: #1150C4;
$core-blue:#0D62FF;
$core-blue-hover:#0A4ECC; // ?
$core-blue-tint-5: #E2ECFF;
$core-blue-tint-4: #CFE0FF;
$dark-blue1: #112F64;
$alice-blue: #F0F5FF;
$cyan-blue: #1B6BFF;
$pattens-blue: #D5E4FF;
$light-blue1: #A1C6F0;
$light-blue2: #EFF5FC;
$light-blue3: #EEF7FE;

  // CBM Colors
$dark-blue2:#a0acc1;
$dark-blue3: #081E45;
$dark-blue4: #45587B;
$dark-blue5:#0B58E5;

$cherry-red: #D92324;
$cherry-red1: #E6003D;
$cherry-red2: #D11314;
$cherry-red-background: #FCF2F2;
$forest-green: #0E8751;
$forest-green-hover: #0D7144;
$forest-green-active: #0A623B;
$forest-green1: #1E8500;

// Secondary Color Palette (Who knew we had this ¯\_(ツ)_/¯)
$smolder: #1F232E;
$urban: #051219;
$reign: #7155D3;
$bramble: #BC43D3;
$berry: #FF638D;
$watermelon-red: #FB543F;
$haute: #FF5A5A;
$tangerine: #FF9271;
$light-pink: #FCEEEF;
$yellow-pumpkin: #FFA700;
$yellow-status: #FFD380;
$yellow-status-border: #FFEDCC;
$green-status-border: #F4FCF3;
$green-status: #BCDAB3;
$lemon: #FFD871;
$turq: #50C3D5;
$lagoon: #1BCFE1;
$green-tea: #1EE2C9;
$minty: #65D0A7;
$seafoam: #47C684;
$salmon: #F1B8B9;

$error-box-background: rgba(252, 242, 242, 0.5);
$box-shadow: rgba(42, 44, 44, 0.2);
$black-shadow: rgba(0, 0, 0, 0.1);
$info-box-background: rgba($color: $vivid-blue-hover, $alpha: 0.1);
$transparent: rgba(255, 255, 255, 0);
$split-gradient1: rgba(254, 254, 254, 1);
$split-gradient2: rgba(251, 251, 251, 1);
$hero-banner-box-shadow: rgba(0, 0, 0, 0.15);

// colors starting with $grey is only for coverage map. Nobody should use them.
$coverage-bg1: rgba(242, 242, 242, 0.66);
$coverage-bg2: rgba(216, 216, 216, 0);

$grey-1: #F9F9F9;
$grey-81: #2E2E2E;
$grey-9: #E8E8E8;
$grey-4: #747474;
$grey-5: #C4C4C4;

// used in network page hero module.
$skynet-blue: #D6EDF8;
$background-blue: #EDF3FD;
$network-top: rgba(27, 207, 225, 0.18);
$network-bottom: rgba(165, 237, 244, 0.3);

// Used for promo badge in activation and customizer for number selection
// The actual color hides the border, so changed the color and added opacity
// for the workaround
$promo-blue: rgba(70, 138, 255, 0.1);
// stylelint-enable

$box-shadow-black: #00000029;