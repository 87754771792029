// base types
@mixin btn-base() {
    @include font-select(bold, 14, $cool-grey13, upper, $spacing: 1.5, $font:'Montserrat');
    display: inline-block;
    vertical-align: middle;
}

@mixin btn-primary-secondary() {
    // remove 1px of padding for the border width
    padding: calc(#{vr(1.5)} - 1px) step(3);
    border-radius: step(0.75);
    border: 2px solid $transparent;
}

// thematic mixins
@mixin btn-primary-light() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    background-color: $core-blue;
    width: 100%;

    @include when-wider-than(tablet) {
        width: auto;
    }

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        background-color: $core-blue-hover;
    }

    &:not(.disabled):active {
        background-color: $dark-blue;
    }

    &.disabled {
        cursor: default;
        background-color: $cool-grey16;
    }
}

@mixin btn-primary-dark() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    background-color: $core-blue;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        background-color: $ocean-blue;
    }

    &:not(.disabled):active {
        background-color: $dark-blue;
    }

    &.disabled {
        cursor: default;
        color: $cool-grey9;
        background-color: $cool-grey12;
    }
}

@mixin btn-primary-blue() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    border-color: $white;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $sharp-blue;
        background-color: $white;
    }

    &:not(.disabled):active {
        color: $sharp-blue;
        background-color: $white;
    }
}

@mixin btn-secondary-light() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $black;
    border-color: $cool-grey21;
    background-color: $transparent;
    width: 100%;

    @include when-wider-than(tablet) {
        width: auto;
    }

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $cool-grey23;
        border-color: $cool-grey21;
    }

    &:not(.disabled):active {
        color: $dark-blue;
        border-color: $dark-blue;
    }

    &.disabled {
        cursor: default;
        color: $white;
        background-color: $cool-grey16;
        border-color: $cool-grey16;
    }
}

@mixin btn-secondary-dark() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $vivid-blue;
    border-color: $vivid-blue;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $vivid-blue-hover;
        border-color: $deep-blue;
    }

    &:not(.disabled):active {
        color: $vivid-blue-hover;
        border-color: $ocean-blue;
    }

    &.disabled {
        cursor: default;
        color: $cool-grey11;
        border-color: $cool-grey11;
    }
}

@mixin btn-secondary-blue() {
    @include btn-base();

    border: 0;
    color: $white;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $white;
    }

    &:not(.disabled):active {
        color: $white;
    }
}

@mixin btn-link-light() {
    @include btn-base();

    border: 0;
    color: $core-blue;
    vertical-align: baseline;
    background: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $core-blue-hover;
    }

    &:not(.disabled):active {
        color: $dark-blue;
    }

    &.disabled {
        color: $cool-grey16;
        cursor: default;
    }
}

@mixin btn-link-dark() {
    @include btn-base();

    border: 0;
    color: $vivid-blue;
    vertical-align: baseline;
    background: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $vivid-blue-hover;
    }

    &:not(.disabled):active {
        color: $vivid-blue-hover;
    }

    &.disabled {
        color: $cool-grey11;
        cursor: default;
    }
}

@mixin btn-link-text() {
    @include font-select(bold, 14, $core-blue, capital, $font:'Montserrat');

    background: $transparent;
    border: 0;
    display: inline-block;
    letter-spacing: normal;
    text-decoration: underline;
    vertical-align: baseline;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $core-blue-hover;
    }

    &:not(.disabled):active {
        color: $dark-blue;
    }

    &.disabled {
        color: $cool-grey16;
        cursor: default;
    }
}
