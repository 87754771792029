.animated {
    animation-duration: 0.5s;
}

@keyframes collapse {
    0% {
        height: 100%;
    }

    99% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

@keyframes expand {
    0% {
        height: 0%;
    }

    99% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-fade-in {
    // Set opacity and translation starting points on dom elements

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes zoom-in {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoom-out {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes promo-fade {
    0% {
        opacity: 0;
    }

    85% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes promo-slide {
    0% {
        transform: translateX(-25px);
    }

    85% {
        transform: translateX(-25px);
    }

    100% {
        transform: translateX(0);
    }
}

.xm-slide-up {
    position: relative;
    transform: translate(0, 150px);
    transition-duration: 1s;
    transition-delay: 0.2s;
}
