@import 'buttons';

*,
*::before,
*::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: $transparent;
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
    font-size: 100%; // 16px
}

// stylelint-disable font-weight-notation
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    color: $cool-grey13;
    font-family: $xfinity-standard;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;

    *:focus-visible {
        outline: 0;
    }

    *::-moz-focus-inner {
        border: 0;
    }

    &.show-outline {
        .white-outline {
            &:focus {
                outline: 1px dashed $white;
            }
        }

        *:focus {
            outline: 1px solid $sky-blue;
        }

        input {
            &:not([type='radio']) {
                &:not([type='checkbox']) {
                    &:focus {
                        outline: 0;
                    }
                }
            }
        }
    }
}
// stylelint-enable font-weight-notation

html,
body {
    height: 100%;
}

button,
input,
select,
textarea {
    font-family: $xfinity-standard;
    line-height: normal;
}

a {
    color: $sky-blue;
    text-decoration: none;
    cursor: pointer;

    &[disabled] {
        pointer-events: none;
        cursor: default;
    }

    &:hover,
    &:active {
        outline: 0;
    }

    .no-touchevents &:hover {
        color: $vivid-blue;
    }

    .no-touchevents &:active {
        color: $dark-blue;
    }

    &.on-blue,
    &.on-dark-gray {
        color: $white;

        .no-touchevents &:hover {
            color: $vivid-blue;
        }

        .no-touchevents &:active {
            color: $vivid-blue;
        }
    }
}

ul,
li {
    list-style: none;
    padding: 0;
}

button {
    padding: 0;
    cursor: pointer;
    position: relative;
    background: $transparent;
    border: 0;
}

b,
.bold {
    @include font-select(bold);
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0.01px;
}

legend {
    border: 0;
    margin: 0;
    padding: 0;
}

.accessibility-hidden {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
}

input {
    height: vr(6);
    padding: vr(1);
    background: $transparent;

    &[type='radio'] {
        appearance: none;
    }

    &[type='text'],
    &[type='number'] {
        font-size: vr(2);
        border: 1px solid $cool-grey7;
    }

    // stylelint-disable declaration-no-important
    &[xmMask] {
        float: none !important;
    }
    // stylelint-enable declaration-no-important
}

label {
    position: relative;

    input {
        &[type='radio'],
        &[type='checkbox'] {
            position: absolute;
            top: -2px;
            left: -2px;
            height: calc(100% + 4px);
            width: calc(100% + 4px);
            box-sizing: content-box;
            padding: 0;
            box-shadow: none;
            border: 0;
            z-index: -1;
            cursor: pointer;

            &[disabled],
            &.disabled {
                pointer-events: none;
                cursor: default;
            }

            &::-ms-check {
                border: 0;
                box-shadow: none;
            }
        }
    }
}

@supports (-moz-appearance:none) {
    label {
        input {
            &[type='checkbox'] {
                top: vr(1);
            }
        }
    }
}

@supports (-webkit-appearance:none) and (stroke-color:transparent) {
    label {
        input {
            &[type='checkbox'] {
                transform: scale(2);
            }
        }
    }
}

.superscript {
    line-height: 0;
    font-size: 50%;
    vertical-align: super;
}

.error-message {
    @include font-select(standard, 14, $cherry-red);
}

.visible {
    opacity: 1;
    visibility: visible;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.display-none {
    display: none;
}

// global btn classes - keeps css dry; always start here
.xm-btn-primary-light {
    @include btn-primary-light();
}

.xm-btn-primary-dark {
    @include btn-primary-dark();
}

.xm-btn-primary-blue {
    @include btn-primary-blue();
}

.xm-btn-secondary-light {
    @include btn-secondary-light();
}

.xm-btn-secondary-dark {
    @include btn-secondary-dark();
}

.xm-btn-secondary-blue {
    @include btn-secondary-blue();
}

.xm-btn-link-light {
    @include btn-link-light();
}

.xm-btn-link-dark {
    @include btn-link-dark();
}

.xm-btn-secondary-dark,
.xm-btn-secondary-light {
    &.promise-btn {
        &.is-loading {
            background: $cool-grey6;
        }
    }
}
